import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface PermissionState {
  level: string[];
}

const initialState: PermissionState = {
  level: [],
};

const permissionSlice = createSlice({
  name: 'permission',
  initialState,
  reducers: {
    setPermissionLevel(state, action: PayloadAction<string[]>) {
      state.level = action.payload;
    },
  },
});

export const { setPermissionLevel } = permissionSlice.actions;
export default permissionSlice.reducer;